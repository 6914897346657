import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import MainHeader from "pages/Header/MainHeader";
import MainFooter from "pages/Footer/MainFooter";
import Info from "components/Typography/Info";
import Success from "components/Typography/Success";
import Danger from "components/Typography/Danger";
import TrendingUp from "@material-ui/icons/TrendingUp";

import bg5 from "../../assets/img/bg5.jpg";
import blog5 from "../../assets/img/examples/blog5.jpg";
import blog6 from "../../assets/img/examples/blog6.jpg";

import land01 from "assets/img/pension/travel/01.jpeg"
import land02 from "assets/img/pension/travel/02.jpeg"
import land03 from "assets/img/pension/travel/03.jpeg"
import land04 from "assets/img/pension/travel/04.jpeg"
import land05 from "assets/img/pension/travel/05.jpeg"
import land06 from "assets/img/pension/travel/06.jpeg"
import land07 from "assets/img/pension/travel/07.jpeg"
import land08 from "assets/img/pension/travel/08.jpeg"
import land09 from "assets/img/pension/travel/island01.jpeg"
import ReactPlayer from "react-player";


// const useStyles = makeStyles(profilePageStyle);
const useStyles = makeStyles(blogPostPageStyle);
export default function TravelPage({ ...rest }) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
        <div>
            <MainHeader/>
            <Parallax
                image={land09}
                filter="dark"
                small
                className={classes.parallax}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem md={8} className={classes.textCenter}>
                            <h2 className={classes.title}>
                                Travel
                            </h2>
                            <h5 className={classes.subtitle}>
                                붉은노을 주변 여행지
                            </h5>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={6}>
                            <div className={classes.profile} style={{paddingTop: 50}}>
                                <div style={{position: 'relative', paddingTop: '56.25%'}}>
                                    <ReactPlayer
                                        style={{position:"absolute", top:0,left:0}}
                                        url='https://youtu.be/LUnDJOwJFd0' controls
                                        width='100%'
                                        height='100%'
                                    />
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                    <div className={classes.section}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                                <Card plain blog>
                                    <CardHeader image plain>
                                        <img src={land01} alt="..." />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: "url(" + land01 + ")",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody plain>
                                        <h4 className={classes.cardTitle}>
                                            두타연
                                        </h4>
                                        <h6 className={classes.description}>
                                            강원도 양구군 방산면 건솔리 수입천 지류에 발달하여 사태리 하류에 위치한 계곡입니다.
                                            두타연은 민간인 출입통제선 북방인 방산면 건솔리 수입천의 지류에 있는데 동면 비아리와 사태리 하류이기도 합니다.
                                            유수량은 많지 않으나, 주위의 산세가 수려한 경관을 이루며, 오염되지 않아 천연기념물인 열목어의 최대서식지로 알려져 있습니다.
                                            높이 10m, 폭 60여m의 계곡물이 한곳에 모여 떨어지는 두타폭포는 굉음이 천지를 진동하고 한낮에도 안개가 자욱하여 시계를 흐리게 합니다.
                                        </h6>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                                <Card plain blog>
                                    <CardHeader image plain>
                                        <img src={land02} alt="..." />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: "url(" + land02 + ")",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody plain>
                                        <h4 className={classes.cardTitle}>
                                            양구 펀치볼마을
                                        </h4>
                                        <h6 className={classes.description}>
                                            양구군 해안면에 위치한 해발400∼500m의 고지대에 발달한 분지로
                                            양구군 북동쪽 약 22km 지점에 자리잡고 있으며,
                                            그 주위가 화채(Punch) 그릇(Bowl)같아 이같이 불리기 시작했습니다.
                                            이 지역은 현재 강원도 양구군 해안면으로서 6개리 470여 가구
                                            1,700여명이 거주하고 있는 우리나라에서 유일하게
                                            민간인 출입통제선 안에 위치한 면(面)입니다.
                                            천연기념물 열목어, 개느삼, 금강초롱, 흰비로 용담, 날개하늘 나리,
                                            해오라비 난초, 끈끈이 주걱 등 많은 희귀 동식물이 서식하고 있습니다.
                                        </h6>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                                <Card plain blog>
                                    <CardHeader image plain>
                                        <img src={land03} alt="..." />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: "url(" + land03 + ")",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody plain>
                                        <h4 className={classes.cardTitle}>
                                            사명산
                                        </h4>
                                        <h6 className={classes.description}>
                                            사명산은 해발이 1,198m이다. 양구, 화천, 춘천 일대와 멀리
                                            인제군 4개 고을을 조망할 수 있다는 데서 사명산이란 이름이 붙었습니다.
                                            계곡의 등로를 따라 2시간 정도 오르면 파로호가 보이는 주능선에 닿고
                                            거기서 계속 올라가면 널찍한 평지를 이룬 정상에 도착할 수 있습니다.
                                            정상에서의 전망은 파로호와 멀리 설악산, 점봉산 등이 보이며,
                                            소양호 물줄기와 청평사가 있는 오봉산도 보입니다.
                                        </h6>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Card plain blog>
                                    <CardHeader image plain>
                                        <img src={land04} alt="..." />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: "url(" + land04 + ")",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody plain>
                                        <h4 className={classes.cardTitle}>
                                            파로호
                                        </h4>
                                        <h6 className={classes.description}>
                                            파로호는 1944년 북한강 협곡을 막아 축조한 화천댐으로 인해
                                            생긴 인공호수입니다.
                                            10억 톤의 물을 담을 수 있는 규모의 호수로 상류에 평화의 댐이있습니다.
                                            일산, 월명봉 등의 높은 산에 둘러싸여 주변경관이 아름다우며,
                                            주위에는 우거진 숲과 맑은 계곡이 잘 어우러져 있습니다.
                                            또한, 공해없는 깊은 물에 잉어, 붕어, 메기, 쏘가리 등 담수어가
                                            많이 서식하고 있어 낚시터로 각광받고 있으며
                                            전국에서 낚시꾼들이 몰려들고 있다.
                                        </h6>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Card plain blog>
                                    <CardHeader image plain>
                                        <img src={land05} alt="..." />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: "url(" + land05 + ")",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody plain>
                                        <h4 className={classes.cardTitle}>
                                            박수근 미술관
                                        </h4>
                                        <h6 className={classes.description}>
                                            자신의 그림에서 인간의 선함과 진실함을 표현코자 했던 화가 박수근.
                                            고호 같은 광기나 피카소의 혁명성보다는 밀레처럼 진솔하고
                                            선량한 시선으로 인생을 들여다본 그는 우리 시대 한국미술계의
                                            독보적인 존재로 자리합니다.
                                            화가의 고향인 강원도 양구군 박수근 선생 생가 터에
                                            200여 평 규모로 건립된 양구군립 박수근 미술관은
                                            작가의 예술관과 인생관을 기리는 동시에 지역 대표 문화 공간이
                                            되고자 하는 의도로 건립된 미술관이다.
                                        </h6>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Card plain blog>
                                    <CardHeader image plain>
                                        <img src={land07} alt="..." />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: "url(" + land07 + ")",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody plain>
                                        <h4 className={classes.cardTitle}>
                                            생태식물원
                                        </h4>
                                        <h6 className={classes.description}>
                                            남북한 생태계 복원센터로 육성하기 위해
                                            전체 면적 18만 9141㎡의 부지에 총 31억을 들여 2004년 6월 문을 열었습니다.
                                            남한 지역 최북단에 위치하였으며, 한국 특산종이자
                                            환경부 보호종인 금강초롱꽃·깽깽이풀·
                                            개느삼을 비롯해 산꼬리풀, 제비동자꽃, 솜다리, 금마타리, 노랑무늬붓꽃,
                                            하늘매발톱, 솔체꽃, 병조회풀 등 400여 종의 희귀식물이 식재되어 있습니다.
                                        </h6>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Card plain blog>
                                    <CardHeader image plain>
                                        <img src={land08} alt="..." />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: "url(" + land08 + ")",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody plain>
                                        <h4 className={classes.cardTitle}>
                                            국토정중앙 천문대
                                        </h4>
                                        <h6 className={classes.description}>
                                            국토정중앙천문대는 우리나라의 중심에서 하늘을 바라보기 위해국토정중앙점 부근에 건설되어 2007년 5월 31일 개관하였다.
                                            주망원경으로 80cm반사망원경이 설치되어 있으며 최신의 천문정보와 더불어 여러 학습체험이 가능한 전시실과
                                            디지털 가상 밤하늘을 감상할 수 있는 천체영실이 설치되어 있다.<br/><br/>
                                            강원도 양구군 남면 국토정중앙로 127  TEL : 033-480-2586
                                        </h6>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <Clearfix />
                </div>
            </div>
            <div className="cd-section"><br/></div>
            <MainFooter/>
        </div>
    );
}
