import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import MainHeader from "pages/Header/MainHeader";
import MainFooter from "pages/Footer/MainFooter";
import Info from "components/Typography/Info";
import Success from "components/Typography/Success";
import Danger from "components/Typography/Danger";
import TrendingUp from "@material-ui/icons/TrendingUp";
import SectionSpecial from "./Sections/SectionSpecial";
import EventSpecial from "./Sections/EventSpecial";

import bg5 from "assets/img/bg5.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import OtherSpecial from "./Sections/OtherSpecial";

// const useStyles = makeStyles(profilePageStyle);
const useStyles = makeStyles(blogPostPageStyle);

export default function SpacialPage({ ...rest }) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
        <div>
            <MainHeader/>
            <Parallax
                image={require("assets/img/pension/main/main_night.jpg").default}
                filter="dark"
                small
                className={classes.parallax}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem md={8} className={classes.textCenter}>
                            <h2 className={classes.title}>
                                Special
                            </h2>
                            <h5 className={classes.subtitle}>
                                붉은노을의 특별함...
                            </h5>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    {/*<GridContainer justify="center">*/}
                    {/*    <GridItem xs={12} sm={12} md={6}>*/}
                    {/*        <div className={classes.profile}>*/}
                    {/*            <div className={classes.name}>*/}
                    {/*                <h3 className={classes.title}><br/><br/></h3>*/}
                    {/*                <h2><b>붉은노을의 특별함</b></h2>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </GridItem>*/}
                    {/*</GridContainer>*/}


                    <EventSpecial/>
                    {/*<OtherSpecial/>*/}
                    <div className={classes.section}>
                        <SectionSpecial/>
                    </div>



                    <Clearfix />
                </div>
            </div>
            <div className="cd-section"><br/></div>
            <MainFooter/>
        </div>
    );
}
