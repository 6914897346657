import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Header from "components/Header/Header.js";
import Button from "components/CustomButtons/Button.js";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

import { Link } from 'react-router-dom';

const useStyles = makeStyles(headersStyle);

export default function MainHeader({ ...rest }) {
    const classes = useStyles();
    return (
        <div className="cd-section" {...rest}>
            <div>
                <Header
                    fixed
                    brand="붉은노을펜션"
                    color="dark"
                    // color="transparent"
                    // changeColorOnScroll={{
                    //     height: 0,
                    //     color: "dark",
                    // }}
                    links={
                        <List className={classes.list + " " + classes.mlAuto}>
                            <ListItem className={classes.listItem}>
                                <Link to="/about-us" className={classes.navLink}>
                                    <Button className={classes.navLink} color="transparent">
                                        About us
                                    </Button>
                                </Link>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <Link to="/rooms" className={classes.navLink}>
                                    <Button className={classes.navLink} color="transparent">
                                        Rooms
                                    </Button>
                                </Link>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <Link to="/spacial" className={classes.navLink}>
                                    <Button className={classes.navLink} color="transparent">
                                        Special
                                    </Button>
                                </Link>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <Link to="/travel" className={classes.navLink}>
                                    <Button className={classes.navLink} color="transparent">
                                        Travel
                                    </Button>
                                </Link>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <Link to="/guide" className={classes.navLink}>
                                    <Button className={classes.navLink} color="transparent">
                                        Guide
                                    </Button>
                                </Link>
                            </ListItem>
                        </List>
                    }
                />
                {/*<div className={classes.sectionBlank} id="blanksection" />*/}
            </div>
        </div>
    );
}
