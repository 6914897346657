import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import MainHeader from "pages/Header/MainHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Parallax from "components/Parallax/Parallax";
import MainFooter from "pages/Footer/MainFooter";
import classNames from "classnames";
import Divider from "@material-ui/core/Divider";
import Quote from "components/Typography/Quote";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import bg10 from "assets/img/pension/main/main_2.jpg";
const useStyles = makeStyles(blogPostPageStyle);

export default function GuidePage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <MainHeader/>
            <Parallax image={require("assets/img/pension/main/main_4.jpg").default} filter="dark" small>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem md={8} className={classes.textCenter}>
                            <h2 className={classes.title}>
                                Guide
                            </h2>
                            <h5 className={classes.subtitle}>
                                예약 / 이용안내
                            </h5>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classes.main}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={6} md={6} className={classNames(
                            classes.mrAuto,
                            classes.mlAuto,
                            classes.textCenter
                        )}>
                            <h3 className={classes.contentTitle}>예약안내</h3>
                            <Divider variant="middle" /><br/>
                                <Quote textClassName={classes.textLeft}
                                    text="입실 시간 : 오후 1시 / 퇴실 시간 : 다음날 오전 11시까지"
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="군면회가족분들은 퇴실시간 연장이 조절 가능하니 반드시 전화상담 부탁드립니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="성수기요금은 기본요금에 2만원 추가됩니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="멀리 지방에서 오시는 군면회가족분들께는 금,토 2박시 할인해드리니 전화주시면 친절히 정성껏 상담해드립니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="백두산 21사단 수료식, 퇴소식 당일 사용 할인 (당일 사용 시간 : 오전10시 ~ 오후 7시 / 전화상담 필수)"
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="픽업문의는 예약시 펜션관리자와 상의 부탁드립니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="객실마다 무선인터넷, 와이파이가 제공되니 노트북을 가져오시면 인터넷을 사용하실 수 있습니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="애견입실 불가능합니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="실내 직화구이 (삼겹살.생선.소고기)금지,야외테이블사용하여 주시기 바랍니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="객실내에서는 반드시 금연을 부탁드립니다."
                                />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} className={classNames(
                            classes.mrAuto,
                            classes.mlAuto,
                            classes.textCenter
                        )}>
                            <h3 className={classes.contentTitle}>이용안내</h3>
                            <Divider variant="middle" /><br/>
                            {/*<p className={classes.textLeft}>*/}
                                <Quote textClassName={classes.textLeft}
                                    text="입실시간 당일 오후1시 ~ 퇴실시간 다음날 오전11시까지입니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="11시-1시는 객실청소시간이니 미리 협의되지 않은 경우 퇴실시간을 지켜주시기 바랍니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="예약인원을 정확하게 지켜주세요. 3세이상은 인원에 포합됩니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="기준인원 초과시 1인1만원씩 추가요금이 있습니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="본인의 부주의로 발생한 사고는 펜션에서 책임지지 않습니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="펜션 입실시 예약인원이 아닌 그 외 지인이나 친척분이 잠시 머무시는 경우에도 1인추가 인원으로 인정합니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="집기나 시설물 파손 및 분실에 대한 책임은 고객님께 있으므로 유의해 주시기 바랍니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="퇴실시 객실 및 주방은 정리정돈 해주시기 바랍니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="쓰레기 분리장은 주차장 입구 오른쪽에 있습니다. 분리수거 부탁드립니다."
                                />
                                <Quote textClassName={classes.textLeft}
                                    text="온수는 기름보일러입니다. 이용에 참고해주세요."
                                />

                            {/*</p>*/}
                        </GridItem>
                    </GridContainer>
                </div>
                <br/><br/>
                <div
                    className={classes.section}
                    style={{ backgroundImage: "url(" + bg10 + ")" }}
                >
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}><br/>
                                <Card profile plain className={classes.textLeft}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={7} md={7}>
                                            <CardBody plain>
                                                <br/>
                                            </CardBody>
                                            <CardFooter profile plain>
                                                <br/>
                                            </CardFooter>
                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={6} md={6} className={classNames(
                            classes.mrAuto,
                            classes.mlAuto,
                            classes.textCenter
                        )}>
                            <h3 className={classes.contentTitle}>환불안내</h3>
                            <Divider variant="middle" /><br/>
                            <Table
                                tableHead={[
                                    "기준일","환불기준",
                                ]}
                                tableData={[
                                    [
                                        "숙박당일","0%",
                                    ],
                                    [
                                        "1일전","0%",
                                    ],
                                    [
                                        "2일전","20%",
                                    ],
                                    [
                                        "3일전","20%",
                                    ],
                                    [
                                        "4일전","50%",
                                    ],
                                    [
                                        "5일전","70%",
                                    ],
                                    [
                                        "6일전","80%",
                                    ],
                                    [
                                        "7일전","80%",
                                    ],
                                    [
                                        "예약완료 ~ 8일전","90%",
                                    ],
                                ]}
                                customCellClasses={[
                                    classes.textCenter,
                                    classes.textCenter,
                                ]}
                                customClassesForCells={[0, 0]}
                                customHeadCellClasses={[
                                    classes.textCenter,
                                    classes.textCenter,
                                ]}
                                customHeadClassesForCells={[0, 0]}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} className={classNames(
                            classes.mrAuto,
                            classes.mlAuto,
                            classes.textCenter
                        )}>
                            <h3 className={classes.contentTitle}>실시간 예약</h3>
                            <Divider variant="middle" /><br/>
                            <p>예약문의는 010-3789-1532로 전화주시면<br/>친철히 상담해드립니다.</p>
                        </GridItem>
                    </GridContainer>
                    <br/><br/>
                </div>
            </div>
            <br/>
            <MainFooter/>
        </div>
    );
}
