import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Call from "@material-ui/icons/Call";
import Footer from "components/Footer/Footer";
import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle";
import {Grid} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import face1 from "../../assets/img/faces/card-profile6-square.jpg";
import classNames from "classnames";
import face2 from "../../assets/img/faces/christian.jpg";
import face3 from "../../assets/img/faces/card-profile4-square.jpg";
import face4 from "../../assets/img/faces/card-profile1-square.jpg";
import face5 from "../../assets/img/faces/marc.jpg";
import face6 from "../../assets/img/faces/kendall.jpg";
import face7 from "../../assets/img/faces/card-profile5-square.jpg";
import face8 from "../../assets/img/faces/card-profile2-square.jpg";

const useStyles = makeStyles(blogPostsPageStyle);

export default function MainFooter() {
    const classes = useStyles();
    return (
        <Footer
            theme="dark"
            content={
                <div className={classes.right}>
                    <p>
                        Copyright &copy; {1900 + new Date().getYear()}{" "} 붉은노을 All Rights Reserved, Created By {" "}
                    <a
                        href="mailto:mirlinux0817@gmail.com"
                        target="_blank"
                        className={classes.aClasses}
                    >
                        Donghee
                    </a>{" "}
                    </p>
                </div>
            }
        >
            <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                    <h4>붉은노을 펜션</h4>
                    <p>
                        붉은노을 펜션을 찾아주셔서 감사합니다.
                    </p>
                    <p>사업자번호 : 313-30-01159</p>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                    <h4>대표자 : 김혜숙</h4>
                    <div>
                        <p>Tel: 010-3789-1532</p>
                        <p>Bank: 신협 010-3789-1532 (예금주 : 김혜숙)</p>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                    <h4>오시는길</h4>
                    <div>
                        <p>강원도 양구군 양구읍 파로호로 542-34</p>
                    </div>
                </GridItem>

            </GridContainer>
        </Footer>

    )
}