import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import blog6 from "assets/img/examples/blog6.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import blog7 from "assets/img/examples/blog7.jpg";

import bbq from "assets/img/pension/special/bbq2.jpg"
import privatePlace from "assets/img/pension/special/g_1.jpg"
import wifi from "assets/img/pension/special/wifi.jpg"

import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.js";

const useStyles = makeStyles(sectionSimilarStoriesStyle);

export default function SectionSpecial() {
  const classes = useStyles();
  return (
    // <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <img src={bbq} alt="..." />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + bbq + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Info>
                      <h6>BBQ</h6>
                    </Info>
                    <h4 className={classes.cardTitle}>
                      바베큐
                    </h4>
                    <h6 className={classes.description}>
                      객실마다 여행에서 빠질 수 없는 바베큐를 할 수 있도록 설치되어 있습니다. 바베큐(숯+그릴) 이용요금은 4인기준 10,000원입니다.
                    </h6>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <img src={privatePlace} alt="..." />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + privatePlace + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Success>
                      <h6>Private</h6>
                    </Success>
                    <h4 className={classes.cardTitle}>
                      독채
                    </h4>
                    <h6 className={classes.description}>
                      유럽식 목조주택형태이며 각 객실마다 개별독채로 되어 있어서 다른 분들께 방해 받지 않고 지내실 수 있습니다.
                    </h6>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <img src={wifi} alt="..." />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + wifi + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Danger>
                      <h6>
                        WIFI / KT QOOK TV
                      </h6>
                    </Danger>
                    <h4 className={classes.cardTitle}>
                      와이파이 / 올레쿡티비
                    </h4>
                    <h6 className={classes.description}>
                      전 객실에 인터넷 와이파이가 다 깔려 있어서 노트북을 가져오시면 편리하게 인터넷을 이용하실 수 있습니다.
                    </h6>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    // </div>
  );
}
