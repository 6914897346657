import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import blog6 from "assets/img/examples/blog6.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import blog7 from "assets/img/examples/blog7.jpg";

import bbq from "assets/img/pension/special/bbq.jpg"
import privatePlace from "assets/img/pension/special/private.jpg"
import wifi from "assets/img/pension/special/wifi.jpg"

import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.js";
import bg5 from "../../../assets/img/bg5.jpg";
import blog5 from "../../../assets/img/examples/blog5.jpg";
import lake01 from "assets/img/pension/travel/04.jpeg"

const useStyles = makeStyles(sectionSimilarStoriesStyle);

export default function OtherSpecial() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Card plain blog>
                            <CardHeader image plain>
                                <img src={lake01} alt="..." />
                                <div
                                    className={classes.coloredShadow}
                                    style={{
                                        backgroundImage: "url(" + lake01 + ")",
                                        opacity: "1",
                                    }}
                                />
                            </CardHeader>
                            <CardBody plain>
                                <Info>
                                    <h6>Landscape</h6>
                                </Info>
                                <h4 className={classes.cardTitle}>
                                    파로호 전망
                                </h4>
                                <h6 className={classes.description}>
                                    저희 붉은노을펜션에서는 파로호의 전망이<br/>아주 시원하게 잘 보이는 곳에 위치해 있습니다.
                                </h6>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Card plain blog>
                            <CardHeader image plain>
                                <img src={lake01} alt="..." />
                                <div
                                    className={classes.coloredShadow}
                                    style={{
                                        backgroundImage: "url(" + lake01 + ")",
                                        opacity: "1",
                                    }}
                                />
                            </CardHeader>
                            <CardBody plain>
                                <Info>
                                    <h6>Landscape</h6>
                                </Info>
                                <h4 className={classes.cardTitle}>
                                    파로호 전망
                                </h4>
                                <h6 className={classes.description}>
                                    저희 붉은노을펜션에서는 파로호의 전망이<br/>아주 시원하게 잘 보이는 곳에 위치해 있습니다.
                                </h6>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
