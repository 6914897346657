import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import MainHeader from "pages/Header/MainHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Parallax from "components/Parallax/Parallax";
import MainFooter from "pages/Footer/MainFooter";
import SectionContacts from "./Sections/SectionContacts";

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";

const useStyles = makeStyles(blogPostsPageStyle);


export default function AboutPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <MainHeader/>
            <Parallax
                image={require("assets/img/pension/main/main_1.jpg").default}
                filter="dark" small
                className={classes.parallax}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem md={8} className={classes.textCenter}>
                            <h2 className={classes.title}>
                                About Us
                            </h2>
                            <h5 className={classes.subtitle}>
                                붉은노을 오시는길
                            </h5>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classes.main}>
                <div className={classes.container}>
                    <SectionContacts/>
                </div>
            </div>
            <div className={classes.sectionBlank} id="blanksection" />
            <br/>
            <MainFooter/>
        </div>
    );
}
