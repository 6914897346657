import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import Carousel from "react-slick";

const useStyles = makeStyles(blogsStyle);

export default function SectionRooms({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className="cd-section" {...rest}>
      {/* Blogs 1 START */}
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              {/*<h2 className={classes.title}>Rooms</h2>*/}
              {/*<br />*/}
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={7} md={7}>
                    <Carousel {...settings}>
                      <div>
                        <img src={require("assets/img/pension/rooms/apple/a_1.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/apple/a_2.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/apple/a_3.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/apple/a_4.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/apple/a_5.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/apple/a_6.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/apple/a_7.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/apple/a_8.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/apple/a_9.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/apple/a_10.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                    </Carousel>
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <Info>
                      <h3 className={classes.cardTitle}>애플 (Apple)</h3>
                    </Info>
                    <h6 className={classes.description1}>
                      객실유형 : 단층 (11평)<br/>
                      특이사항 : 전객실 wifi<br/>
                      기준인원3 / 최대인원5<br/>
                      체크인13:00 / 체크아웃11:00<br/>
                      요금 (비수기 기준) : 주중 100,000 / 주말 110,000<br/>
                    </h6>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <Info>
                      <h3 className={classes.cardTitle}>민트 (Mint)</h3>
                    </Info>
                    <h6 className={classes.description1}>
                      객실유형 : 단층 (7평)<br/>
                      특이사항 : 전객실 wifi<br/>
                      기준인원2 / 최대인원3<br/>
                      체크인13:00 / 체크아웃11:00<br/>
                      요금 (비수기 기준) : 주중 70,000 / 주말 90,000<br/>
                    </h6>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <Carousel {...settings}>
                      <div>
                        <img src={require("assets/img/pension/rooms/mint/m_1.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/mint/m_2.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/mint/m_3.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/mint/m_4.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/mint/m_5.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/mint/m_6.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/mint/m_7.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/mint/m_8.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/mint/m_9.jpg").default} alt="Third slide" className="slick-image" />
                      </div>
                    </Carousel>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={7} md={7}>
                    <Carousel {...settings}>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_1.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_2.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_3.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_4.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_5.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_6.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_7.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_8.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_9.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_10.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_11.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/lemon/l_12.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                    </Carousel>
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <Info>
                      <h3 className={classes.cardTitle}>레몬밤 (Lemon balm)</h3>
                    </Info>
                    <h6 className={classes.description1}>
                      객실유형 : 복층 (11평)<br/>
                      특이사항 : 전객실 wifi<br/>
                      기준인원3 / 최대인원5<br/>
                      체크인13:00 / 체크아웃11:00<br/>
                      요금 (비수기 기준) : 주중 100,000 / 주말 110,000<br/>
                    </h6>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <Info>
                      <h3 className={classes.cardTitle}>버베나 (Verbena)</h3>
                    </Info>
                    <h6 className={classes.description1}>
                      객실유형 : 복층 (11평)<br/>
                      특이사항 : 전객실 wifi<br/>
                      기준인원3 / 최대인원5<br/>
                      체크인13:00 / 체크아웃11:00<br/>
                      요금 (비수기 기준) : 주중 100,000 / 주말 110,000<br/>
                    </h6>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <Carousel {...settings}>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_1.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_2.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_3.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_4.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_5.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_6.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_7.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_8.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_9.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_10.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_11.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/verbena/v_12.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                    </Carousel>
                  </GridItem>
                </GridContainer>
              </Card>
              {/*<Card plain blog className={classes.card}>*/}
              {/*  <GridContainer>*/}
              {/*    <GridItem xs={12} sm={7} md={7}>*/}
              {/*      <Carousel {...settings}>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N01.jpeg").default} alt="First slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N00.jpg").default} alt="Second slide" className="slick-image"/>*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N02.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N03.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N04.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N05.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N06.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N07.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N08.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N09.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N10.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N11.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N12.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/herbrose/N13.jpeg").default} alt="Third slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*      </Carousel>*/}
              {/*    </GridItem>*/}
              {/*    <GridItem xs={12} sm={5} md={5}>*/}
              {/*      <Info>*/}
              {/*        <h3 className={classes.cardTitle}>허브로즈 (Herb rose)</h3>*/}
              {/*      </Info>*/}
              {/*      <h6 className={classes.description1}>*/}
              {/*        객실유형 : 단층 (16평)<br/>*/}
              {/*        특이사항 : 전객실 wifi<br/>*/}
              {/*        기준인원4 / 최대인원8<br/>*/}
              {/*        체크인13:00 / 체크아웃11:00<br/>*/}
              {/*        요금 (비수기 기준) : 주중 120,000 / 주말 130,000<br/>*/}
              {/*      </h6>*/}
              {/*    </GridItem>*/}
              {/*  </GridContainer>*/}
              {/*</Card>*/}
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={7} md={7}>
                    <Carousel {...settings}>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_1.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_2.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_3.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_4.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_5.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_6.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_7.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_8.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_9.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_10.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_11.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_12.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_13.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_14.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                      <div>
                        <img src={require("assets/img/pension/rooms/greentime/g_15.jpg").default} alt="First slide" className="slick-image" />
                      </div>
                    </Carousel>
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <Info>
                      <h3 className={classes.cardTitle}>그린타임 (Green Time)</h3>
                    </Info>
                    <h6 className={classes.description1}>
                      객실유형 : 단층 (12평)<br/>
                      특이사항 : 전객실 wifi<br/>
                      기준인원3 / 최대인원5<br/>
                      체크인13:00 / 체크아웃11:00<br/>
                      요금 (비수기 기준) : 주중 110,000 / 주말 120,000<br/>
                    </h6>
                  </GridItem>
                </GridContainer>
              </Card>
              {/*<Card plain blog className={classes.card}>*/}
              {/*  <GridContainer>*/}
              {/*    <GridItem xs={12} sm={5} md={5}>*/}
              {/*      <Info>*/}
              {/*        <h3 className={classes.cardTitle}>황토방</h3>*/}
              {/*      </Info>*/}
              {/*      <h6 className={classes.description1}>*/}
              {/*        객실유형 : 단층 (11평)<br/>*/}
              {/*        특이사항 : 전객실 wifi<br/>*/}
              {/*        기준인원3 / 최대인원5<br/>*/}
              {/*        체크인13:00 / 체크아웃11:00<br/>*/}
              {/*        요금 (비수기 기준) : 주중 100,000 / 주말 110,000<br/>*/}
              {/*      </h6>*/}
              {/*    </GridItem>*/}
              {/*    <GridItem xs={12} sm={7} md={7}>*/}
              {/*      <Carousel {...settings}>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/yellow/y_1.jpg").default} alt="First slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/yellow/y_2.jpg").default} alt="First slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/yellow/y_3.jpg").default} alt="First slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/yellow/y_4.jpg").default} alt="First slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/yellow/y_5.jpg").default} alt="First slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/yellow/y_6.jpg").default} alt="First slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/yellow/y_7.jpg").default} alt="First slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/yellow/y_8.jpg").default} alt="First slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <img src={require("assets/img/pension/rooms/yellow/y_9.jpg").default} alt="First slide" className="slick-image" />*/}
              {/*        </div>*/}
              {/*      </Carousel>*/}
              {/*    </GridItem>*/}
              {/*  </GridContainer>*/}
              {/*</Card>*/}
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Blogs 1 END */}

    </div>
  );
}
