import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import DirectionsCar from "@material-ui/icons/DirectionsCar"
import DirectionsBus from "@material-ui/icons/DirectionsBus"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle";
import {RenderAfterNavermapsLoaded, NaverMap} from "react-naver-maps";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Button from "components/CustomButtons/Button";
import Danger from "../../../components/Typography/Danger";
import TrendingUp from "@material-ui/icons/TrendingUp";
import CardFooter from "../../../components/Card/CardFooter";
import christian from "../../../assets/img/faces/christian.jpg";
import Favorite from "@material-ui/icons/Favorite";
import ChatBubble from "@material-ui/icons/ChatBubble";


const useStyles = makeStyles(contactsStyle);
const useStylesMap = makeStyles(blogPostPageStyle);

const RegularMap = () => {
  const [position, setPosition] = useState({lat:38.128822210785785, lng:127.9546467848371})
  const [mapRef, setMapRef] = useState()
  useEffect(() => {
    if (!mapRef) return
    let marker = new window.naver.maps.Marker({
        position: position,
        map: mapRef.map
    })

  },[mapRef]);
  const classes = useStyles();
  return (
    <RenderAfterNavermapsLoaded
        ncpClientId={"34gecdk1vg"}
        error={<p>Maps Load Error</p>}
        loading={<p>Maps Loading...</p>}>
        <NaverMap
            mapDivId={'react-naver-map-dispatch'} // default: react-naver-map
            naverRef={ref => { setMapRef(ref) }}
            style={{
              width: '100%', // 네이버지도 가로 길이
              height: '400px' // 네이버지도 세로 길이
            }}
            defaultCenter={{ lat: position.lat, lng: position.lng }} // 지도 초기 위치
            center={{ lat: position.lat, lng: position.lng }} // 지도 초기 위치
            defaultZoom={10} // 지도 초기 확대 배율
        />
    </RenderAfterNavermapsLoaded>
  );
};

export default function SectionContacts({ ...rest }) {
  const [checked, setChecked] = useState([]);
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>

      <div>
          <GridContainer>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                  <Card>
                      <CardBody>
                          <InfoArea
                              className={classes.infoArea2}
                              title="연락처"
                              description={
                                  <p>
                                      <a href="tel:+82-010-3789-1532">
                                          <i className="fas fa-link" /> 010-3789-1532
                                      </a>
                                  </p>
                              }
                              icon={Phone}
                              iconColor="rose"
                          />
                      </CardBody>
                  </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                  <Card>
                      <CardBody>
                          <InfoArea
                              className={classes.infoArea2}
                              title="오시는길"
                              description={
                                  <p>
                                      강원도 양구군 양구읍<br/>파로호로 542-34
                                  </p>
                              }
                              icon={PinDrop}
                              iconColor="rose"
                          />

                          <CopyToClipboard text={"강원도 양구군 양구읍 파로호로 542-34"}>
                              <Button color="facebook" simple>
                                  <i className="fas fa-copy" />클립보드에 주소복사
                              </Button>
                          </CopyToClipboard>
                      </CardBody>
                  </Card>
              </GridItem>
          </GridContainer>
          <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                  <Card>
                      <RegularMap />
                  </Card>
              </GridItem>
          </GridContainer>
          <GridContainer>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                  <Card>
                      <CardBody>
                          <InfoArea
                              className={classes.infoArea2}
                              title="자가용 이용"
                              description={
                                  <p>
                                      강원도 양구군 양구읍<br/>파로호로 542-34
                                  </p>
                              }
                              icon={DirectionsCar}
                              iconColor="rose"
                          />
                          <CopyToClipboard text={"강원도 양구군 양구읍 파로호로 542-34"}>
                              <Button color="facebook" simple>
                                  <i className="fas fa-copy" />클립보드에 주소복사
                              </Button>
                          </CopyToClipboard>
                      </CardBody>
                  </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                  <Card>
                      <CardBody>
                          <InfoArea
                              className={classes.infoArea2}
                              title="대중교통 이용"
                              description={<></>}
                              icon={DirectionsBus}
                              iconColor="rose"
                          />
                          <p>
                              중앙고속도로 → 춘천IC에서 '인제,양구,화천,소양강댐' 방면으로 우측 고속도로 출구 →
                              신북교차로에서 '양구,오음,아침못길' 방면으로 우측방향 → 춘양로 →
                              학조리사거리에서 '학조리' 방면으로 좌회전 → 학안로 → 박수근로 → 파라호로→ 붉은노을 도착
                          </p>
                      </CardBody>
                  </Card>
              </GridItem>
          </GridContainer>

      </div>
      {/* Contact us 2 END */}
    </div>
  );
}
