import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SectionRooms from "./Sections/SectionRooms";
import SectionSpecial from "../Special/Sections/SectionSpecial.js";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import MainHeader from "pages/Header/MainHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Parallax from "components/Parallax/Parallax";
import MainFooter from "pages/Footer/MainFooter";

const useStyles = makeStyles(blogPostPageStyle);

export default function RoomPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <MainHeader/>
            <Parallax image={require("assets/img/pension/rooms/greentime/g_4.jpg").default} filter="dark" small>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem md={8} className={classes.textCenter}>
                            <h2 className={classes.title}>
                                Rooms
                            </h2>
                            <h5 className={classes.subtitle}>
                                붉은노을 객실보기
                            </h5>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classes.main}>
                <div className={classes.container}>
                    <SectionRooms />
                </div>
            </div>
            <div className={classes.section}>
                <SectionSpecial />
            </div>
            <MainFooter/>
        </div>
    );
}
