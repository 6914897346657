import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";

import SignupPage from "views/SignupPage/SignupPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import MainPage from "pages/MainPage/MainPage";
import RoomPage from "pages/Rooms/RoomPage";
import AboutPage from "pages/About/AboutPage";
import SpacialPage from "pages/Special/SpacialPage";
import TravelPage from "pages/Travel/TravelPage";
import GuidePage from "pages/Guide/GuidePage";

var hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <Route path="/about-us" component={AboutPage}/>
            <Route path="/spacial" component={SpacialPage}/>
            <Route path="/travel" component={TravelPage}/>
            <Route path="/guide" component={GuidePage}/>
            <Route path="/signup-page" component={SignupPage}/>
            <Route path="/error-page" component={ErrorPage}/>
            <Route path="/rooms" component={RoomPage}/>
            <Route path="/" component={MainPage}/>
        </Switch>
    </Router>,
    document.getElementById("root")
);
