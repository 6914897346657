import React, {useState, useEffect} from "react";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import cardBlog5 from "../../../assets/img/examples/card-blog5.jpg";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import Subject from "@material-ui/icons/Subject";
import Icon from "@material-ui/core/Icon";
import Delete from "@material-ui/icons/Delete";
import GridContainer from "../../../components/Grid/GridContainer";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards";
import Success from "../../../components/Typography/Success";
import Refresh from "@material-ui/icons/Refresh";
import cardBlog6 from "../../../assets/img/examples/card-blog6.jpg";
import Receipt from "@material-ui/icons/Receipt";
import sports01 from "assets/img/pension/special/sportsman_01.jpeg"
import sports02 from "assets/img/pension/special/sportsman_02.jpeg"
import soldier01 from "assets/img/pension/special/soldier_01.jpeg"
import soldier02 from "assets/img/pension/special/soldier_02.jpeg"


const useStyles = makeStyles(styles);

export default function EventSpecial() {
    const [activeRotate1, setActiveRotate1] = React.useState("");
    const [activeRotate2, setActiveRotate2] = React.useState("");
    const [activeRotate3, setActiveRotate3] = React.useState("");
    React.useEffect(() => {
        if (window) {
            window.addEventListener("resize", addStylesForRotatingCards);
        }
        addStylesForRotatingCards();
        return function cleanup() {
            if (window) {
                window.removeEventListener("resize", addStylesForRotatingCards);
            }
        };
    });
    const addStylesForRotatingCards = () => {
        var rotatingCards = document.getElementsByClassName(classes.cardRotate);
        for (let i = 0; i < rotatingCards.length; i++) {
            var rotatingCard = rotatingCards[i];
            var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
            var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
            cardFront.style.height = "unset";
            cardFront.style.width = "unset";
            cardBack.style.height = "unset";
            cardBack.style.width = "unset";
            var rotatingWrapper = rotatingCard.parentElement;
            var cardWidth = rotatingCard.parentElement.offsetWidth;
            var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
            rotatingWrapper.style.height = cardHeight + "px";
            rotatingWrapper.style["margin-bottom"] = 30 + "px";
            cardFront.style.height = "unset";
            cardFront.style.width = "unset";
            cardBack.style.height = "unset";
            cardBack.style.width = "unset";
            cardFront.style.height = cardHeight + 35 + "px";
            cardFront.style.width = cardWidth + "px";
            cardBack.style.height = cardHeight + 35 + "px";
            cardBack.style.width = cardWidth + "px";
        }
    };
    const classes = useStyles();
    return (
        <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={6}>
                <div
                    className={
                        classes.rotatingCardContainer +
                        " " +
                        classes.manualRotate +
                        " " +
                        activeRotate1
                    }
                >
                    <Card className={classes.cardRotate}>
                        <div
                            className={
                                classes.front + " " + classes.wrapperBackground
                            }
                            style={{ backgroundImage: `url(${sports01})` }}
                        >
                            <CardBody background className={classes.cardBodyRotate}>
                                <h3 className={classes.cardTitleWhite}>운동선수 가족우대</h3>
                                <br/><br/><br/><br/><br/><br/>
                                <div className={classes.textCenter}>
                                    <Button
                                        round
                                        color="rose"
                                        onClick={() =>
                                            setActiveRotate1(classes.activateRotate)
                                        }
                                    >
                                        <Refresh /> 자세히보기
                                    </Button>
                                </div>
                            </CardBody>
                        </div>
                        <div
                            className={classes.back + " " + classes.wrapperBackground}
                            style={{ backgroundImage: `url(${sports02})` }}
                        >
                            <CardBody background className={classes.cardBodyRotate}>
                                <h3 className={classes.cardTitleWhite}>운동선수 가족우대</h3>
                                <br/>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <p className={classes.cardTitleWhite}>
                                        운동선수 및 가족분들은 퇴실시간<br/>
                                        연장이 조절 가능하오니<br/>
                                        연제든 문의주세요<br/>
                                        (퇴실시간 오전 11시)
                                    </p>
                                </a>
                                <br/>
                                <div className={classes.textCenter}>
                                    <Button
                                        round
                                        color="rose"
                                        onClick={() => setActiveRotate1("")}
                                    >
                                        <Refresh /> 뒤로...
                                    </Button>
                                </div>
                            </CardBody>
                        </div>
                    </Card>
                </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6}>
                <div
                    className={
                        classes.rotatingCardContainer +
                        " " +
                        classes.manualRotate +
                        " " +
                        activeRotate2
                    }
                >
                    <Card className={classes.cardRotate}>
                        <div
                            className={
                                classes.front + " " + classes.wrapperBackground
                            }
                            style={{ backgroundImage: `url(${soldier02})` }}
                        >
                            <CardBody background className={classes.cardBodyRotate}>
                                <h3 className={classes.cardTitleWhite}>군인가족우대</h3>
                                <br/><br/><br/><br/><br/><br/>
                                <div className={classes.textCenter}>
                                    <Button
                                        round
                                        color="danger"
                                        onClick={() =>
                                            setActiveRotate2(classes.activateRotate)
                                        }
                                    >
                                        <Refresh /> 자세히보기
                                    </Button>
                                </div>
                            </CardBody>
                        </div>
                        <div
                            className={classes.back + " " + classes.wrapperBackground}
                            style={{ backgroundImage: `url(${soldier01})` }}
                        >
                            <CardBody background className={classes.cardBodyRotate}>
                                <h3 className={classes.cardTitleWhite}>군인가족우대</h3>
                                <br/>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <p className={classes.cardTitleWhite}>
                                        군면회가족분들은 퇴실시간 연장이<br/>
                                        조절가능하오니 연제든 문의주세요<br/>
                                        (퇴실시간 오전 11시)
                                    </p>
                                </a>
                                <br/>
                                <div className={classes.textCenter}>
                                    <Button
                                        round
                                        color="danger"
                                        onClick={() => setActiveRotate2("")}
                                    >
                                        <Refresh /> 뒤로...
                                    </Button>
                                </div>
                            </CardBody>
                        </div>
                    </Card>
                </div>
            </GridItem>
        </GridContainer>
    )

}
