import React from "react";

import MainHeader from "pages/Header/MainHeader";
import MainFooter from "pages/Footer/MainFooter";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import dg1 from "assets/img/pension/main/main_1.jpg";
import dg2 from "assets/img/pension/main/main_2.jpg";
import dg3 from "assets/img/pension/main/main_3.jpg";
import dg4 from "assets/img/pension/main/main_4.jpg";
import dg5 from "assets/img/pension/main/main_5.jpg";
import dg6 from "assets/img/pension/main/main_night.jpg";
import Carousel from "react-slick";
import {makeStyles} from "@material-ui/core/styles";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle";
const useStyles = makeStyles(headersStyle);

export default function MainPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };
    const classes = useStyles();
    return (
        <div>
            <MainHeader/>
            <Carousel {...settings}>
                {/* Carousel 1 START */}
                <div>
                    <div
                        className={classes.pageMainHeader}
                        style={{ backgroundImage: `url("${dg6}")` }}
                    >
                        <div className={classes.container}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    className={classNames(
                                        classes.mlAuto,
                                        classes.mrAuto,
                                        classes.textCenter
                                    )}>
                                    <h2 className={classes.title}>붉은노을 펜션</h2>
                                    <h5 className={classes.title}>
                                        오늘, 지금 붉은노을에서 행복하세요
                                    </h5>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                {/* Carousel 1 END */}
                {/* Carousel 1 START */}
                <div>
                    <div
                        className={classes.pageMainHeader}
                        style={{ backgroundImage: `url("${dg1}")` }}
                    >
                        <div className={classes.container}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    className={classNames(
                                        classes.mlAuto,
                                        classes.mrAuto,
                                        classes.textCenter
                                    )}>
                                    <h2 className={classes.title}>붉은노을 펜션</h2>
                                    <h5 className={classes.title}>
                                        오늘, 지금 붉은노을에서 행복하세요
                                    </h5>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                {/* Carousel 1 END */}
                {/* Carousel 2 START */}
                <div>
                    <div
                        className={classes.pageMainHeader}
                        style={{ backgroundImage: `url("${dg2}")` }}
                    >
                        <div className={classes.container}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    className={classNames(
                                        classes.mlAuto,
                                        classes.mrAuto,
                                        classes.textCenter
                                    )}
                                >
                                    <h2 className={classes.title}>붉은노을 펜션</h2>
                                    <h5 className={classes.title}>
                                        오늘, 지금 붉은노을에서 행복하세요
                                    </h5>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                {/* Carousel 2 END */}
                {/* Carousel 3 START */}
                <div>
                    <div
                        className={classes.pageMainHeader}
                        style={{ backgroundImage: `url("${dg3}")` }}
                    >
                        <div className={classes.container}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    className={classNames(
                                        classes.mlAuto,
                                        classes.mrAuto,
                                        classes.textCenter
                                    )}>
                                    <h2 className={classes.title}>붉은노을 펜션</h2>
                                    <h5 className={classes.title}>
                                        오늘, 지금 붉은노을에서 행복하세요
                                    </h5>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                {/* Carousel 3 END */}
                {/* Carousel 4 START */}
                <div>
                    <div
                        className={classes.pageMainHeader}
                        style={{ backgroundImage: `url("${dg4}")` }}
                    >
                        <div className={classes.container}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    className={classNames(
                                        classes.mlAuto,
                                        classes.mrAuto,
                                        classes.textCenter
                                    )}>
                                    <h2 className={classes.title}>붉은노을 펜션</h2>
                                    <h5 className={classes.title}>
                                        오늘, 지금 붉은노을에서 행복하세요
                                    </h5>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                {/* Carousel 4 END */}
                {/* Carousel 5 START */}
                <div>
                    <div
                        className={classes.pageMainHeader}
                        style={{ backgroundImage: `url("${dg5}")` }}
                    >
                        <div className={classes.container}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    className={classNames(
                                        classes.mlAuto,
                                        classes.mrAuto,
                                        classes.textCenter
                                    )}>
                                    <h2 className={classes.title}>붉은노을 펜션</h2>
                                    <h5 className={classes.title}>
                                        오늘, 지금 붉은노을에서 행복하세요
                                    </h5>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                {/* Carousel 5 END */}
            </Carousel>
            {/*<MainFooter/>*/}
        </div>
    );
}
